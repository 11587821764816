import React from "react"

import styles from "../../styles.module.less"

const ErrorMessage = ({ error }) => {
  if (!error) return null
  return <span className={styles.error}>{error}</span>
}

export default ErrorMessage
