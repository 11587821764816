/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import NavBarWrap from "components/NavBarWrap"
import styles from "./styles.module.less"
import { shouldFocus } from "./styles.module.less"

import logo from "./img/logo.png"

const Layout = ({
  children,
  backgroundColor,
  navColor,
  bannerBgColor,
  isArchive,
}) => {
  const [state, setFocus] = useState({ shouldFocus: false })
  // keep it pretty but maintain accessibility for tab users
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      // the "I am a keyboard user" key (tab)
      if (!state.shouldFocus) {
        setFocus({ shouldFocus: true })
      }
      window && window.removeEventListener("keydown", handleFirstTab)
    }
  }

  // window is not defined when building, so avoid window is undefined error
  if (typeof window !== `undefined`) {
    window && window.addEventListener("keydown", handleFirstTab)
  }

  let cls = [styles.Layout, state.shouldFocus ? shouldFocus : ""].join(" ")
  let bgColor = backgroundColor ? backgroundColor : "transparent"
  return (
    <div>
      {/* <Banner bannerBgColor={bannerBgColor} /> */}
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div id="layout" className={cls} style={{ background: bgColor }}>
            {isArchive ? (
              <ArchiveNavbar />
            ) : (
              <NavBarWrap
                siteTitle={data.site.siteMetadata.title}
                navColor={navColor}
              />
            )}

            <main id="main">{children}</main>
          </div>
        )}
      />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const ArchiveNavbar = () => {
  return (
    <header className={styles.ArchiveNavBar}>
      <a href="https://thesis.agency">
        <svg
          id="thesis-logo"
          width={48}
          height={16}
          viewBox="0 0 48 16"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Thesis"
          className="thesis-logo"
          fill="#42504C"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0769 13.0042C10.0521 13.8381 9.94545 14.158 10.9257 14.313C11.1491 14.335 11.2484 14.465 11.2236 14.6369L11.1749 15.0939C11.1491 15.2448 11.025 15.3538 10.8264 15.3538H6.68104C6.65989 15.3528 6.63875 15.3458 6.62035 15.3348C6.60196 15.3238 6.58541 15.3078 6.57346 15.2888C6.57346 15.2888 6.52012 15.0109 6.49254 14.8339C6.46771 14.7919 6.47506 14.6369 6.47506 14.6369C6.47506 14.6369 6.53484 14.334 6.71415 14.313C7.7514 14.139 7.75048 13.7281 7.75048 12.5563L7.73117 4.40785C7.73117 3.606 7.25208 3.10709 6.57438 2.97711C6.39506 2.93412 6.31506 2.84714 6.31506 2.67417V2.23925C6.31506 2.06528 6.37483 1.9563 6.51461 1.89232C7.81025 1.30743 8.40796 0.976487 9.22544 0.174637C9.27234 0.116648 9.33119 0.0706563 9.39648 0.0406619C9.46176 0.0106674 9.53349 -0.00332994 9.60429 0.000669311H9.94269C10.1027 0.0226652 10.1818 0.131645 10.1818 0.282617L10.088 6.94037C10.0871 6.98037 10.0981 7.02036 10.1183 7.05435C10.1386 7.08835 10.168 7.11434 10.202 7.13034C10.236 7.14634 10.2737 7.15034 10.3105 7.14234C10.3473 7.13434 10.3804 7.11434 10.4061 7.08635C11.253 6.16052 12.1846 5.39366 13.4912 5.39366C15.0471 5.39366 15.9639 6.71542 15.9639 8.34111V12.5563C15.9639 13.7281 15.7864 14.139 16.8237 14.313C17.003 14.335 17.083 14.465 17.0628 14.6369L17.0232 15.0929C17.0168 15.1668 16.9837 15.2348 16.9322 15.2838C16.8807 15.3318 16.8136 15.3568 16.7446 15.3528H12.774C12.5744 15.3528 12.4503 15.2438 12.4255 15.0929L12.3758 14.6369C12.3501 14.464 12.4503 14.334 12.6738 14.313C13.7643 14.256 13.5032 13.9251 13.517 12.9752C13.5133 12.8473 13.5105 12.7083 13.5105 12.5563V8.55207C13.5105 7.79322 13.0921 7.31631 12.454 7.31631C11.7698 7.31631 10.9036 7.82921 10.1845 8.83602C10.1174 8.933 10.0806 9.05098 10.0815 9.17196L10.0825 12.5563C10.0852 12.7193 10.0815 12.8683 10.0769 13.0042Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.73203 5.14253C5.89111 5.14253 5.99134 5.2725 5.95088 5.44447L5.79088 6.49627C5.75042 6.67024 5.65111 6.75722 5.47179 6.75722H4.09247L3.75224 6.73523L3.73201 12.8311C3.73201 13.416 3.97109 13.7199 4.44925 13.7199C4.86857 13.7199 5.40927 13.242 5.68789 12.9391C5.82766 12.7871 6.00513 12.8321 6.08421 12.9851L6.44192 13.589C6.52192 13.7409 6.52744 13.8509 6.40514 13.9599C5.54812 14.8057 4.4888 15.6086 3.412 15.6086C2.05659 15.6086 1.29796 14.6977 1.31819 13.289V7.63906C1.31819 6.8802 0.90715 6.82321 0.289213 6.64925C0.130131 6.60625 0.0298998 6.49727 0.0105893 6.32531L0.00415245 5.65443C-0.00688214 5.57445 0.00415246 5.49246 0.0363367 5.41947C0.0685209 5.34649 0.326914 5.2855 0.390363 5.24351C1.60693 4.37567 1.75681 3.71679 2.53016 1.68717C2.56602 1.61218 2.62027 1.55019 2.6874 1.5082C2.75453 1.46621 2.83177 1.44521 2.90901 1.44921H3.56649C3.72649 1.44921 3.8258 1.55719 3.78626 1.75216L3.73201 5.14353H5.73203V5.14253Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.8372 12.4427C25.8225 12.3677 25.7848 12.3007 25.7314 12.2518L25.4519 12.0348C25.2928 11.9268 25.1337 11.9918 25.0344 12.1218C24.4551 12.8806 23.5585 13.3795 22.661 13.4005C21.1585 13.4425 20.0661 12.5537 19.6219 11.203C19.61 11.165 19.6118 11.122 19.6284 11.086C19.644 11.049 19.6725 11.021 19.7074 11.006L24.8146 8.93438C24.9351 8.89238 25.0142 8.7844 25.0142 8.61044V8.30749C25.0133 8.19052 25.0004 8.07354 24.9746 7.96056C24.6555 6.57282 23.7185 5.14209 21.7636 5.14209C19.1318 5.14209 17.186 7.46165 17.186 10.4541C17.186 13.2506 19.1125 15.7001 21.8242 15.6771C23.9576 15.6561 25.2735 14.0734 25.8123 12.6637C25.8427 12.5947 25.8519 12.5177 25.8372 12.4427ZM19.5015 9.74522C19.4868 9.74522 19.473 9.74222 19.4601 9.73623C19.4472 9.73023 19.4362 9.72223 19.427 9.71123C19.4178 9.70023 19.4123 9.68823 19.4086 9.67524C19.0077 8.16052 19.724 6.72979 21.2247 6.72979C22.2362 6.72979 22.7548 7.49565 22.6132 8.40148C22.6095 8.42247 22.6003 8.44247 22.5866 8.45947C22.5728 8.47646 22.5544 8.48946 22.5341 8.49846L19.5419 9.73523C19.53 9.74122 19.5153 9.74522 19.5015 9.74522Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.5779 15.3527C26.5457 15.3587 26.5136 15.3577 26.4823 15.3477C26.451 15.3387 26.4216 15.3217 26.3977 15.2997C26.3729 15.2767 26.3526 15.2487 26.3398 15.2167C26.326 15.1847 26.3195 15.1497 26.3195 15.1147L26.28 12.2743C26.2791 12.2343 26.2855 12.1943 26.2993 12.1573C26.3131 12.1203 26.3333 12.0863 26.36 12.0573C26.3857 12.0293 26.417 12.0063 26.4519 11.9913C26.486 11.9763 26.5228 11.9693 26.5595 11.9703H26.8998C27.0598 11.9703 27.1591 12.0573 27.2593 12.2093C27.8377 13.1201 28.8529 14.3429 30.3683 14.3429C31.126 14.3429 31.6842 13.9529 31.6842 13.3461C31.6842 12.7422 31.2971 12.5352 30.5522 12.1363C30.4685 12.0913 30.3812 12.0443 30.2883 11.9943C29.6492 11.6474 28.7904 11.1275 28.1917 10.7155C27.0552 9.93569 26.4814 8.93587 26.4814 7.78709C26.4814 6.13839 27.6768 5.18457 29.3522 5.18457C29.96 5.18457 30.5283 5.37054 30.9807 5.52451C31.3007 5.63349 31.5729 5.72647 31.7881 5.72647C32.0143 5.72647 32.1476 5.65048 32.2699 5.5805C32.3729 5.52151 32.4686 5.46752 32.6056 5.46752H32.8851C33.0249 5.46752 33.1049 5.5535 33.1049 5.70547L33.1444 8.242C33.1444 8.41497 33.0451 8.54495 32.8851 8.54495H32.566C32.406 8.54495 32.2865 8.41497 32.2065 8.28599C32.201 8.27799 32.1955 8.27 32.1899 8.261L32.1789 8.244V8.243C31.8322 7.7221 30.852 6.24837 29.4957 6.24837C28.9375 6.24837 28.4952 6.6183 28.4952 7.13721C28.4952 7.74409 29.0368 8.15602 29.7145 8.61093C30.3729 9.04485 31.2097 9.56475 31.9086 9.95368C32.9449 10.5386 33.8433 11.4064 33.8433 12.7062C33.8433 14.4848 32.4667 15.5686 30.6525 15.5686C30.1237 15.5686 29.423 15.4827 28.8639 15.4127C28.5789 15.3767 28.3251 15.3447 28.1366 15.3297C27.5159 15.2877 27.0763 15.2877 26.5779 15.3527Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9551 15.3487C39.9864 15.3577 40.0195 15.3597 40.0508 15.3537C40.5492 15.2887 40.9887 15.2887 41.6067 15.3307C41.7952 15.3457 42.049 15.3776 42.3331 15.4136C42.8922 15.4846 43.5699 15.5696 44.0986 15.5696C45.9129 15.5696 47.2895 14.4858 47.2895 12.7072C47.2895 11.4074 46.2991 10.5396 45.2628 9.95467C44.5648 9.56574 43.7271 9.04584 43.0687 8.61192C42.391 8.15701 41.8724 7.74509 41.8724 7.1382C41.8724 6.6193 42.2936 6.24937 42.8517 6.24937C44.2366 6.24937 45.1911 7.83207 45.4973 8.33898L45.5037 8.34997C45.5782 8.48295 45.6913 8.61892 45.8513 8.62792L46.1695 8.64492C46.3285 8.65392 46.4343 8.52994 46.4416 8.35697L46.46 5.70547C46.46 5.5535 46.38 5.46752 46.2412 5.46752H45.9616C45.8246 5.46752 45.729 5.52151 45.626 5.5805C45.5037 5.65048 45.3704 5.72647 45.1442 5.72647C44.929 5.72647 44.6568 5.63349 44.3368 5.52451C43.8844 5.37054 43.3391 5.18457 42.7313 5.18457C41.0558 5.18457 39.8604 6.13839 39.8604 7.78708C39.8604 8.93486 40.5381 9.86769 41.6747 10.6485C42.2733 11.0615 43.1092 11.5814 43.7483 11.9273C44.666 12.4262 45.2564 12.7301 45.2564 13.401C45.2564 14.0079 44.6973 14.3978 43.9405 14.3978C42.4241 14.3978 41.3087 13.1191 40.7303 12.2082C40.6301 12.0563 40.5308 11.9693 40.3708 11.9693H40.0296C39.9928 11.9683 39.9561 11.9753 39.922 11.9903C39.888 12.0053 39.8568 12.0273 39.8301 12.0563C39.8043 12.0843 39.7832 12.1183 39.7694 12.1563C39.7556 12.1932 39.7492 12.2332 39.7501 12.2732L39.7905 15.1137C39.7905 15.1487 39.797 15.1837 39.8108 15.2157C39.8246 15.2477 39.8439 15.2757 39.8687 15.2987C39.8954 15.3227 39.9239 15.3387 39.9551 15.3487Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.2707 5.20524C37.4583 5.09726 37.6937 5.00928 37.8142 5.00928C37.9126 5.00928 37.9935 5.07427 37.9935 5.18225L37.9733 12.5549C37.9733 13.7257 37.817 14.1376 38.8533 14.3115C39.0326 14.3335 39.1126 14.4635 39.0933 14.6355L39.0767 15.0914C39.0703 15.1654 39.0372 15.2334 38.9848 15.2824C38.9333 15.3304 38.8662 15.3554 38.7972 15.3514L34.5939 15.3334C34.5259 15.3374 34.4578 15.3124 34.4063 15.2644C34.3539 15.2154 34.3217 15.1474 34.3144 15.0734L34.3208 14.6175C34.3006 14.4445 34.3797 14.3145 34.5599 14.2936C35.5972 14.1196 35.7673 13.7267 35.7673 12.5559L35.748 8.84256C35.748 8.04071 35.21 7.73877 34.5305 7.60879C34.3512 7.5658 34.2721 7.47782 34.2721 7.30485V6.98091C34.2721 6.80794 34.3319 6.69896 34.4716 6.63398C35.9972 5.83412 36.2077 5.78013 37.2707 5.20524Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.4433 1.08639C36.5656 1.05839 36.6143 1.05939 36.7376 1.06739C36.8488 1.08239 36.9307 1.12938 37.0217 1.19137C37.0502 1.21036 37.0796 1.23036 37.1109 1.25136C37.2801 1.36633 37.4971 1.58829 37.6231 1.75426C37.739 1.90723 37.8373 2.07719 37.9137 2.25816C37.9201 2.27316 37.9256 2.28815 37.9311 2.30315C37.9927 2.45812 38.0268 2.5791 38.0102 2.73107C37.9964 2.85504 37.9698 2.93203 37.9238 3.02401C37.9081 3.058 37.8898 3.093 37.8695 3.13199C37.7638 3.33895 37.6231 3.52491 37.4567 3.67988C37.1937 3.92283 36.8764 4.0978 36.5353 4.17378C36.2364 4.25377 35.9771 4.24877 35.692 4.01082C35.5192 3.87484 35.1504 3.23297 35.0943 2.99401C35.0833 2.95302 35.0723 2.91403 35.0621 2.87504C35.0336 2.76906 35.0116 2.66708 35.0235 2.5531C35.0382 2.40013 35.0769 2.29215 35.144 2.16218C35.1541 2.14218 35.1642 2.12119 35.1762 2.10019C35.304 1.86624 35.4741 1.65928 35.6746 1.49431C35.8658 1.33634 36.1215 1.16837 36.3458 1.11338C36.3789 1.10338 36.4111 1.09439 36.4433 1.08639Z"
          />
        </svg>
      </a>
    </header>
  )
}
