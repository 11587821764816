import React, { useState } from "react"
import { string } from "prop-types"

import { Link } from "gatsby"
import Reveal from "react-reveal/Reveal"
import Form from "./Form"

import styles from "./styles.module.less"

import whiteLogo from "./img/thesis-logo-white.svg"
import blackLogo from "./img/thesis-logo-black.svg"
import close from "./img/close.svg"

const Navbar = ({ siteTitle, navColor }) => {
  const [isHidden, showNavigation] = useState(true)

  const handleMenuToggle = e => {
    showNavigation(!isHidden)
  }

  // let nvColor = navColor ? navColor : "translate"
  let logo = navColor === "black" ? blackLogo : whiteLogo
  let fillColor = navColor === "black" ? "#000000" : "#FFFFFF"
  return (
    <>
      <header data-component="Navbar" className={styles.Navbar}>
        <Link to="/" replace className={styles.logo} title={siteTitle}>
          <img src={logo} alt={siteTitle} />
        </Link>
        <a
          className={styles.menu}
          href="#main-nav"
          title="View menu"
          onClick={handleMenuToggle}
        >
          <svg viewBox="0 0 72 16" width="23" height="5">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g fill={fillColor}>
                <circle cx="8" cy="8" r="8" />
                <circle cx="36" cy="8" r="8" />
                <circle cx="64" cy="8" r="8" />
              </g>
            </g>
          </svg>
        </a>
      </header>

      <div>
        <Reveal duration={10000}>
          <nav id="main-nav" className={styles.MainNav} hidden={isHidden}>
            <div className={styles.blocks}>
              <div className={styles.LeftNav}>
                <a
                  onClick={handleMenuToggle}
                  title="Hide menu"
                  href="#"
                  className={styles.close}
                >
                  <img src={close} alt="Hide menu" />
                </a>
                <div className={styles.links}>
                  <Link onClick={handleMenuToggle} to="/about">
                    About
                  </Link>
                  <Link onClick={handleMenuToggle} to="/people">
                    People
                  </Link>
                  <Link onClick={handleMenuToggle} to="/work">
                    Work
                  </Link>
                  <Link onClick={handleMenuToggle} to="/news">
                    News
                  </Link>
                  <Link onClick={handleMenuToggle} to="/careers">
                    Careers
                  </Link>
                  <Link onClick={handleMenuToggle} to="/contact-us">
                    Contact
                  </Link>
                </div>
              </div>
              <div className={styles.RightNav}>
                <div className={styles.Newsletter}>
                  <h1>Email exchange</h1>
                  <div className={styles.input}>
                    <Form />
                  </div>
                </div>

                <div className={styles.info}>
                  <div className={styles.address}>
                    <h1>Address</h1>
                    <a href="https://goo.gl/maps/muoHExDXm8LJ1qrZ6">
                      1417 NW Everett Street
                      <br />
                      Suite 300
                      <br />
                      Portland, OR 97209
                    </a>
                  </div>
                  <div className={styles.phone}>
                    <h1>Call us</h1>
                    <a href="tel:503-221-6200">(503) 221-6200</a>
                  </div>
                </div>
                <div className={styles.social}>
                  <a
                    href="https://www.instagram.com/thesis.agency/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://www.linkedin.com/company/thesis-agency/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                  <a
                    href="http://facebook.com/thesis.agency"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                  <a
                    href="https://twitter.com/Thesis_Agency"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </div>
                <a
                  className={styles.emailroi}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://emailer.emailroi.com/dbadmin/login.pl"
                >
                  Login
                </a>
              </div>
            </div>
          </nav>
        </Reveal>
      </div>
    </>
  )
}

Navbar.propTypes = {
  siteTitle: string,
}

export default Navbar
