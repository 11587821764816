import React, { Component } from "react"
import NavBar from "../NavBar"

import styles from "./styles.module.less"

export default class NavBarWrap extends Component {
  constructor(props) {
    super(props)
    this.state = { isHide: false, isInverted: false }
    this.ref = React.createRef()
  }
  scrollTop = () => {
    return Math.max(
      document.documentElement.scrollTop,
      document.body.scrollTop,
      document.scrollingElement.scrollTop
    )
  }
  hideBar = () => {
    let { isHide } = this.state
    let y = this.scrollTop()
    y > 80 && window.scrollY > this.prev
      ? !isHide && this.setState({ isHide: true })
      : isHide && this.setState({ isHide: false })

    this.prev = window.scrollY
    this.adjustColor()
  }
  adjustColor = () => {
    if (this.props.navColor) return
    if (!this.ref || !this.ref.current) return
    let bar = this.ref.current
    if (!bar.getBoundingClientRect || !document.elementsFromPoint) return
    let { bottom, left } = bar.getBoundingClientRect()
    let els = document.elementsFromPoint(left, bottom)
    let classNames = Array.from(els).map(el =>
      el.className.toString().toLowerCase()
    )
    let heroes = classNames.filter(cls => cls.indexOf("hero") > -1)
    this.setState({ isInverted: !heroes.length })
  }
  componentDidMount() {
    window.addEventListener("scroll", this.hideBar)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideBar)
  }
  render() {
    let classHide = this.state.isHide ? `${styles.hide}` : ""
    let classInvert = this.state.isInverted ? `${styles.swap}` : ""
    let cls = [styles.topbar, classHide, classInvert].join(" ")
    return (
      <div ref={this.ref} className={cls}>
        <NavBar
          siteTitle={this.props.siteTitle}
          navColor={this.props.navColor}
        />
      </div>
    )
  }
}
