import React, { Component } from "react"

import { noop } from "lodash"
import ErrorMessage from "../ErrorMessage"

import styles from "../../styles.module.less"

export default class InnerForm extends Component {
  static defaultProps = {
    complete: false,
    handleChange: noop,
    email: "",
    errors: { emailError: "" },
    failure: "",
  }

  handleInputChange = e => {
    this.props.handleChange(e.target.value)
  }

  renderForm() {
    const { errors, value } = this.props
    return (
      <section className={styles.InnerForm}>
        <input
          id="control_EMAIL"
          label="Email"
          type="email"
          value={value}
          onChange={this.handleInputChange}
          required
          placeholder="Give us yours to get ours"
          className={styles.email}
        />
        <span className={styles.borda}></span>
        <input
          required
          //onClick={this.handleValidation}
          type="submit"
          value="Submit"
          className={styles.submit}
        />
        <ErrorMessage error={errors.emailError} />
      </section>
    )
  }

  render() {
    return this.renderForm()
  }
}
