import React, { Component } from "react"
import axios from "axios"
import InnerForm from "./InnerForm"
import styles from "../styles.module.less"

export default class Form extends Component {
  state = {
    complete: false,
    failure: "",
    email: "",
  }

  handleChange = email => {
    this.setState({ email })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { email } = this.state
    const body = {
      formSourceName: "StandardForm",
      "RECIPIENT_ID_*": "929001373683",
      EMAIL: email,
    }
    //const body = new URLSearchParams()
    //body.append("email", email)
    // console.log("body data", body)

    axios
      .post("https://thesis-web-api.herokuapp.com/submit-form", body)
      .then(res => {
        console.log("rressssss", res)
        this.setState({ complete: true })
      })
      .catch(err => {
        console.log("errrrr", err)
        this.setState({ complete: false, failure: err.message })
      })
  }

  renderForm() {
    const { email } = this.state
    return (
      <section className={styles.Contact}>
        <div>
          <form
            method="POST"
            onSubmit={this.handleSubmit}
            className={styles.emailForm}
          >
            <InnerForm handleChange={this.handleChange} value={email} />

            {/*<input type="hidden" name="formSourceName" value="StandardForm" />
            <input type="hidden" name="sp_exp" value="yes" />
    */}
          </form>
        </div>
      </section>
    )
  }

  renderComplete() {
    return (
      <div className={styles.completeMessage}>
        <p>Thank you! We&apos;ve received your email.</p>
      </div>
    )
  }

  renderFailure() {
    return (
      <div className={styles.completeMessage}>
        <p>Something went wrong. Please try again later.</p>
      </div>
    )
  }

  render() {
    const { complete, failure } = this.state
    if (failure) return this.renderFailure()
    return complete ? this.renderComplete() : this.renderForm()
  }
}
